import PageTitle from "../Components/PageTitle";

export default function Reviews() {

  const facebookReviews = [
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmandy.martin.355138%2Fposts%2Fpfbid0BGuCcE3namjfAgvF9hgZeRGwM6VGL8wtgnWQYQnDLqwgbFyGpCNkixVZAEevFZm9l&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fphoebe.martin.5811%2Fposts%2Fpfbid02LtJpyRKKYokgPctKthTiSy1DbuVsowaPgsJxycjttATS11zYgVoesxUKGGrUsAP5l&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Flyn.sobey%2Fposts%2Fpfbid02NEL1Zt9vXjg9bAE1K3RbCqr7BHt91apWLVXfnvNnms7CwPW7Us6hgbvunzx5rhPNl&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjay.m.thompson.9%2Fposts%2Fpfbid04ctQEKNu5jKaENasoS5H98UxwPqeuDm6Yce8oUCNwH4BmeS7ppfgNSQUkiGanozfl&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmarie.sullivangilbury%2Fposts%2Fpfbid02yPiadGSD4gqtcVdg8DcavNyEByoZF5BjUQrFVJY6xv6D4avoEMEbhNUft9i7yntal&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fclaire.booth.33%2Fposts%2Fpfbid025HYfi5J48a5Wh7tK9RXs7CeYxn4kZcT1WZjxHv1QUkoV6bqDnyHcycXuNeopMsa8l&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Flouise.doherty.714%2Fposts%2Fpfbid07WCNgoddLrSjTvvGy37S1dPjgxxANcR2DBLzasfNXXinYF54tebNfsJTbGVLWUA1l&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjaninec.g%2Fposts%2Fpfbid0Wn9FXyunvKy35mrydzwRd7YZ6adVZgXiAcdNCzWG29fM4TsRQMvvHyXnYmz8oGU6l&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Florraine.croot%2Fposts%2Fpfbid02P8kPsvneJtPLDP3xr2YbygAjwaeUUQJjjoXPcynVgMmmf61yi2Zp1cfpQbUb9yHyl&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnatalie.bentley.98031%2Fposts%2Fpfbid03655wS8UozJsbT8Vyru5tAhFYo36SGNSja26g1Gyb59sjV2ycYeoGGz7zCksfdZUil&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid03SdZQtJrh2hJUhFnyJ8icsHdTVJ36bFwPNpikQkUAYVvMtnziyyDLytGznd79ajkl%26id%3D100009273525747&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Felaine.robinson.3154%2Fposts%2Fpfbid02NyuKsWeu7HXnSe9jUNAP6tcdYLfxMo5ao5u86R3L5ELZvPN98P1aYAb9VbDDKiXql&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffuntimefrank%2Fposts%2Fpfbid02y3x6Laq12gaqHkaPhxj7nRTtvANuub4EkBeMPrZyXaXFSNqVv7KFdcusyg7czUdtl&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Femma.chapman.9066%2Fposts%2Fpfbid0HEp7nbcT1iiS3eNP1rBBLcjWRHHZa3KUecn7yw7m885b44X5BBV3M4fopHKcD3Fgl&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkatie.harris.9028194%2Fposts%2Fpfbid0Wqxww5KuQuogj6UQVyzhDBJ3XLWmjpwcZHudvZm6CRFtunLM7ps7sJK1jRkg6ojml&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsharon.pinder.7%2Fposts%2Fpfbid032LXFLEqj2yf3es5MpzLSZtGfRkatMdYFS7oTTAXdGGmDUWJgx7HbYXjT7v1cR4Pyl&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fadammarckelly%2Fposts%2Fpfbid06scRSuo8LgLxwut45B6dYKsubJLvC3w6J84szh9LzFD9ydmjJEFn5d4mYbtbZpprl&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Flee.wheeler.14%2Fposts%2Fpfbid0Ak3CYdR5ydEYQSjfymCBjFr3XdgDTKrbHdAHjvqPft6aDhdjVnrjuML8gHN4yJXFl&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02id5DuYJ2w25MxXjuYaav86yKdRN8jTb25EfFio7x5Ti27q9QELb4LMXPxZtuCRkAl%26id%3D615281963&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02anDLXRpiGm3ejd7jVnchwTr59QrehHSCuSdXx1B5282s2voz9P1pBto4rJQwopCzl%26id%3D100008295872923&show_text=true",
    "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbev.cowell%2Fposts%2Fpfbid02to2VpMNZz79PJzmY23SJDPyTpQpLgDsc5CncwT1PrVBknyC4yfRrysMh5NUx6gVol&show_text=true",
  ];

  //TODO: Add reviews from google - can't actually embed, would need to use API and that costs money

  return (
    <div>
      <PageTitle title={"Reviews"} />
      <div className="px-2 items-center flex">
        <h1 className="text-2xl font-bold">Check and Leave Reviews here:</h1>
        <a className="pl-4 text-xl underline text-blue-500" target="_blank" href="https://www.facebook.com/profile.php?id=100085459600083&sk=reviews">The Ear Nurse Facebook page</a>
      </div>

      <div className="px-2 mt-8 flex flex-wrap gap-5">
        {facebookReviews.map((review) => {
          return (
            <iframe className="w-[30rem] h-[250px] md:h-[300px] lg:h-[250px] overflow-hidden" title={review} src={review} scrolling="yes" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          )
        })}
      </div>
    </div>
  )
}